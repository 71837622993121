@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,500');

// Bootstrap library.
@import 'bootstrap';

// Base-theme overrides.
@import 'overrides';

// Base-theme overrides.
@import 'variable-overrides';

// Additional mixins.
@import 'user-mixins';

// Material design colors
@import '_colors';


html, body{
	min-height:100%;
	position: relative;
	margin:0 !important;
	font-weight: 400;
	font-size: @font-size-small;
	@media @normal{
		font-size: @font-size-base;
	}
}
a, .btn, button, .myanimated{
	.transition(all 300ms ease);
} 
h1{
    margin:0.5em 0 1em 0;
    padding:0;
}
h2, h3, h4, h5{
    margin:1em 0 0.5em 0;
    padding:0;
	font-weight:400;
}
h2.block-title{
	margin:0 0 1em 0;
	font-size:@font-size-h3;
	text-align: center;
	@media @tablet{
		font-size: @font-size-h2;
	}
	@media @wide{
		font-size: @font-size-h1;
	}
}
#admin-menu{
	font-size:12px;
	display:none;
	@media @tablet{
		display:block;
	}
}
body.admin-menu{
	margin-top:0!important;
	@media @tablet{
		margin-top:29px!important;
	}
}
body.noscroll{
	position: fixed;
	left:0;
	top:0;
	width:100%;
	@media @normal{
		position: static;
	}
}
.btn{
	padding:.5em 2em;
	background: #039be5;
	font-weight: 400;
	color:@white;
	.box-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
	.border-radius(0.5em);
	&:hover{
		background:@gray-dark;
		color:@white;
		.box-shadow(0px 2px 15px rgba(0, 0, 0, 0.33));
	}
}
.blue{
	color:@theme-color-1;
}
.light-blue{
	color:@theme-color-2;
}
.underlined{
	position: relative;
	padding-bottom:1rem;
	&:after{
		height:1px;
		width:40px;
		position:absolute;
		left:50%;
		bottom:0;
		margin-left:-20px;
		content:'';
		display: block;
		background: @theme-color-2;
	}
}
.overlay{
	display:none;
	&.shadow{
		top:-56px;
		position:absolute;
		display:block;
		width:100%;
		height:100%;
		background:rgba(0, 0, 0, 0.5);
		z-index:1000;
		@media @normal{
			display: none;
		}
	}
}
.header{
	color:@text-color;
	display:block;
	position:fixed;
	width:100%;
	min-height:100%;
	z-index: 1001;
	left:-100%;
	top:0;
	padding:0;
	font-size: @font-size-xsmall;
	.myanimated;
	.header_wrapper{
		.transition(all 300ms ease);
		position: absolute;
		width:calc(~'100% - 40px');
		height:100%;
		left:-100%;
		background:@grey-50;
		overflow-y: scroll;
		z-index: 10000;
		.myanimated;
	}
	.close{
		.transition(all 300ms ease);
		color:@theme-color-2;
		font-size:16pt;
		width:40px;
		height:40px;
		background:@grey-50;
		position: absolute;
		top:0;
		right:100%;
		padding:4px 10px;
		z-index:10001;
		.box-shadow(inset 0px 0px 5px rgba(0, 0, 0, 0.15));
		cursor: pointer;
		opacity:1;
		font-weight:normal;
		padding-top:0.35em;
	}
	.clickme{
		position: absolute;
		z-index: 9998;
		height:100%;
		width:100%;
	}
	&.open{
		left:0;
		top:0;
		.header_wrapper{
			left:0;
			.box-shadow(0 0 35px rgba(0, 0, 0, 0.75));
		}
		.close{
			right:0;
		}
	}
	.topmenu{
		.myanimated;
		.logo{
			padding:1em 0;
			margin:0.5em 0 2em 0;
			border-bottom:1px solid @gray;
			text-align:center;
			.myanimated;
			img{
				max-width:200px;
				width:100%;
				height: auto;
				.myanimated;
			}
		}
		.topbuttons{
			padding: 1em 0;
			font-size: @font-size-xsmall;
			.btn{
				font-size: @font-size-small;
				margin-right:0.35em;
				padding:0.25em 0.5em;
				&.demo-btn{
					background: @gray-mid-dark;
				}
			}
		}
		.mainmenu{
			padding: 1em 0;
			.nav{
				padding:0;
				margin:0;
				li{
					display: block;
					font-size: @font-size-small;
					a{
						display: block;
						margin:0;
						padding:1em 0;
						background:none;
						border-bottom:1px solid @grey-200;
						color: @text-color;
						font-weight: 400;
						&:hover{
							text-decoration:underline;
							color:@light-blue-600;
						}
						&.active{
							color:@light-blue-600;
						}
					}
					&.dropdown{
						.dropdown-menu{
							display: block;
							border:none;
							.border-radius(0);
							padding:0.25em 1em;
							margin:-1px 0 0 0;
							position:relative;
							float:none;
							.box-shadow(none);
							background: none;
							li{
								a{
									padding: 0.25em 0;
									border-bottom:none;
									color:@gray-base;
									font-weight: 400;
									text-transform: none;
								}
							}
						}
					}
				}
			}
		}
	}
	@media @tablet{
		.topmenu{
			.logo{
				margin:0;
				border-bottom:none;
			}
			.topbuttons{
				.btn{
					padding:0.5em 2em;
				}
			}
		}
	}
	@media @normal{
		z-index: 100;
		position:fixed;
		width:100%;
		min-height:auto;
		left:0;
		color:@gray-base;
		font-size:@font-size-small;
		border-bottom:1px solid @gray-more-light;
		.header_wrapper{
			position: relative;
			width:100%;
			height:auto;
			left:0;
			.clearfix;
			overflow-y: visible;
		}
		&:after{
			display:none;
		}
		.close{
			display: none;
		}
		.clickme{
			display: none;
		}
		.topmenu{
			padding:1em 2em;
			background: @white;
			.clearfix;
			.logo{
				margin:0;
				padding:0;
				border-top:none;
				border-bottom:none;
				text-align:left;
				img{
					width:100%;
					max-width:215px;
					height:auto;
				}
			}
			.topbuttons{
				padding:0.5em 0;
				font-size: @font-size-xsmall;
				text-align: right;
				.btn{
					font-size: @font-size-small;
					margin-right:1em;
				}
			}
			.mainmenu{
				padding:1.3em 0;
				.nav{
					li{
						display: inline-block;
						font-size: @font-size-base;
						text-transform: uppercase;
						a{
							display: block;
							margin:0;
							padding:0 0.35em;
							background:none;
							border-bottom:none;
							.border-radius(3px);
							font-weight: 400;
						}
						&.dropdown{
							.dropdown-menu{
								position:absolute;
								float:left;
								display: none;
								.box-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
								padding:1em;
								background: @white;
								li{
									display: block;
									a{
										padding: 0.25em 0;
										border-bottom:none;
										color:@gray-base;
										&.active{
											background: none;
											color:@theme-color-1;
										}
										&:hover{
											color:@theme-color-1;
										}
									}
								}
							}
							&:hover{
								.dropdown-menu{
									display: block;
								}
							}
						}
					}
				}
				&.usermenu{
					.nav{
						text-align: right;
					}
				}
			}
		}
		&.collapsed{
			.topmenu{
				/*padding:0.5em 3em;*/
				.mainmenu{
					.nav{
						li{
							a{
								padding:0em 0.5em;
							}
						}
					}
				}
			}
		}
	}
	@media @wide{
		font-size: @font-size-small;
		.topmenu{
			padding:1em 1em;
			.mainmenu{
				.nav{
					li{
						a{
							padding:0 0.5em;
						}
					}
				}
			}
		}
		&.collapsed{
			.topmenu{
				.mainmenu{
					.nav{
						li{
							a{
								padding:0em 0.5em;
							}
						}
					}
				}
			}
		}
	}
}

.mobile_header{
		display:block;
		padding:0.5em 15px;
		background:@grey-50;
		color:@theme-color-2;
		vertical-align: middle;
		position: fixed;
	    top: 0;
	    z-index: 100;
	    width: 100%;
	    .box-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
		@media @normal{
			display:none;
		}
		.menu-btn{
			display: inline-block;
			font-size: 18pt;
			font-weight: normal;
			margin-right:0.5em;
			padding:0.25em;
			border:none;
			.border-radius(3px);
			width:40px;
			height:40px;
			text-align: center;
			cursor: pointer;
		}
		.header-title{
			display: inline-block;
			font-size: @font-size-large;
			line-height: 1;
			font-weight: 400;
			padding:0;
			img{
				width:140px;
				margin-top:-0.25em;
			}
		}
}
.slider{ 
	margin-top:54px;
	position: relative;
	@media @normal{
		margin-top:5.5em;
	}
	.views-field-nothing{
		.field-content{
			display: block;
			min-height:110px;
			position: relative;
			box-sizing: border-box;
			padding:2em 0;
			.slider-bg-img{
				position: absolute;
				width: 100%;
				/*height:100%;*/ 
				left:0;
				top:0;
				z-index: 10;
				img{
					height:100%;
					width:100%;
					object-fit: cover;
    				object-position: 0 center;
    				@media @tablet{
    					object-position: 30% center;
    				}
    				@media @xwide{
    					object-position: 0 center;
    				}
				}
			}
			.sec-slider-image{
				position: relative;
				z-index: 20;
				text-align: center;
				img{
					display: inline-block;
				}
			}
			.slider-txt{
				position: relative;
				z-index: 20;
				text-align: center;
				.slider-header{
					margin:1em 0 1em 0;
					font-weight: 500;
				}
				.slider-body{
					font-size: @font-size-xsmall; 
					display: none;
				}
				.slider-links{
					margin-top:20px;
					font-size: @font-size-xsmall;
					.btn{
						font-size: @font-size-xsmall;
					}
				}
			}
			@media @tablet{
				min-height:260px;
				.slider-txt{
					text-align:left;
					.slider-header{
						font-size: @font-size-large;
						font-weight: 400;
					}
					.slider-body{
						font-size: @font-size-xsmall;
						display: block;
						margin-bottom:1em;
					}
					.slider-links{
						font-size: @font-size-xsmall;
						.btn{
							font-size: @font-size-xsmall;
						}
					}
				}
			}
			@media @normal{
				min-height:340px;
				.slider-txt{
					.slider-header{
						font-size: @font-size-h3;
					}
					.slider-body{
						font-size: @font-size-small;
						display: block;
					}
					.slider-links{
						font-size: @font-size-small;
						.btn{
							font-size: @font-size-small;  
						}
					}
				}
			}
			@media @wide{
				min-height:400px;
				.slider-txt{
					.slider-header{
						font-size: @font-size-h2;
					}
					.slider-body{
						font-size: @font-size-base;
						display: block;
					}
					.slider-links{
						font-size: @font-size-small;
						.btn{
							font-size: @font-size-small;
						}
					}
				}
			}
			@media @xwide{
				min-height:575px;
				.slider-txt{
					.slider-header{
						font-size: @font-size-h2;
					}
					.slider-body{
						font-size: @font-size-base;
						display: block;
					}
					.slider-links{
						font-size: @font-size-small;
						.btn{
							font-size: @font-size-small;
						}
					}
				}
			}
		}
	}
}

.content{
	padding:54px 0 0 0;
	> .container{
		padding-top:2em;
		padding-bottom:4em;
		background: @white;
		@media @normal{
			padding-top:2em;
			.block-webform{
				width:50%;
			}
		}
		@media @wide{
			.block-webform{
				width:33%;
			}
		}
	}
}
.customers, .partners{
	background: @white;
	padding:1.5em 0 1em 0;
	position: relative;
	z-index: 21;
	display: none;
		@media @tablet{
			display: block;
		}
	.block-title{
		font-size: @font-size-large;
		text-align: center;
		font-weight: 400;
		color:@gray-mid-dark;
		text-transform: none;
		@media @normal{
			font-size: @font-size-h4;
		}
	}
	.slide__content,
	.slick-track{
		display: flex;
		align-content: center;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		list-style: none;
		margin:0;
		padding:0;
		flex-direction: column;
		min-width:100%;
		@media @tablet{
			flex-direction: row;
		}
		.slide__grid,
		.slick__slide{
			flex:1 1 auto;
			padding:1em;
			margin:0;
			box-sizing: border-box;
			float:none;
			.grid__content{
				display: flex;
				align-content: center;
				justify-content: center;
				align-items: center;
			}
		}
		img{
			flex:1 1 100%;
			width:100%;
			height: auto;
			max-width: 220px;
			min-width: 100px;
			/*-webkit-filter: contrast(60%) brightness(140%) opacity(70%) grayscale(100%);
    		filter: contrast(60%) brightness(140%) opacity(70%) grayscale(100%);
    		.myanimated;
    		&:hover{
    			-webkit-filter: contrast(100%) brightness(100%) opacity(100%) grayscale(0%);
    			filter: contrast(100%) brightness(100%) opacity(100%) grayscale(0%);
    		}*/
			@media @wide{
				max-width: 220px;
			}
		}
	}
	.slick__arrow{
		text-align: center;
		margin-top:2em;
		@media @tablet{
			position: rel;
			left:0;
			margin-top:0;
			top:50%;
		}
		button{
			display: inline-block;
			font-size: 0;
			background: none;
			border:none;
			padding:0;
			margin: 0;
			width:50px;
			position: absolute;
			top:calc(~'50% - 10px');
			&:before{
				display:inline-block;
				width:40px;
				height:40px;
				.border-radius(20px);
				border:1px solid @gray;
				text-align: center;
				font-family: @font-family-awesome;
				font-size: @font-size-h3;
				font-weight: normal;
				color:@gray;
				padding:0;
				line-height: 36px;
			}
			&:nth-child(1){
				left:-20px;
				&:before{
					content:'\f104';
				}
				@media @tablet{
					left:-30px;
				}
			}
			&:nth-child(2){
				right: -20px;
				&:before{
					content:'\f105';
				}
				@media @tablet{
					right:-30px;
				}
			}
		}
	}
}
.testimonials{
	background: @gray-lighter;
	padding:2em 0;
	text-align: center;
	.field-content{
		color:@gray-mid-dark;
	}
	.views-field-title{
		font-weight: 500;
		color:@text-color;
		margin-top:1em;
		font-size: @font-size-large;
		.field-content{
			&:before{
				content:'-';
				display: inline-block;
				padding-right:0.5em;
			}
		}
	}
	.slick__arrow{
		text-align: center;
		margin-top:1em;
		button{
			display: inline-block;
			font-size: 0;
			background: none;
			border:none;
			padding:0;
			margin: 0;
			width:50px;
			&:before{
				display:inline-block;
				width:40px;
				height:40px;
				.border-radius(20px);
				border:1px solid @gray;
				text-align: center;
				font-family: @font-family-awesome;
				font-size: @font-size-h3;
				font-weight: normal;
				color:@gray;
				padding:0;
				line-height: 36px;
			}
			&:nth-child(1){
				&:before{
					content:'\f104';
				}
			}
			&:nth-child(2){
				&:before{
					content:'\f105';
				}
			}
		}
	}
}
.page-features{
	.content{
		> .container{
			width:100%;
			padding:0;
			h1{
				display: none;	
			}
		}
	}
}
.features{
	padding: 3em 0 1em 0;
	.hp-features-item{
		margin-bottom:2em;
		.views-field-field-image{
			.field-content{
				min-height: 100px;
				height:140px;
				margin: 0 0 1em 0;
				@media @tablet{
					height:160px;
				}
				@media @normal{
					height:200px;
				}
				@media @wide{
					height:200px;
				}
				@media @xwide{
					height:200px;
				}
				img{
					margin: 0 auto;
					max-width:100%;
					height:100%;
					object-fit: contain;
					max-width: 280px;
				}
			}
		}
		.views-field-title{
			font-size: @font-size-h4;
			text-align: center;
			margin: 0 0 0.5em 0;
			a{
				color: @text-color;
			}
		}
		.views-field-view-node{
			text-align: center;
			margin-top:1em;
			a{
				.btn;
				background: @white;
				color:@gray-mid-dark;
				border-color:@gray-more-light;
				font-size: @font-size-small;
				font-weight: 400;
			}
		}
		.views-field-body{
			color:@gray-mid-dark;
		}
	}
}

.feature-page-item{
	position: relative;
	.features-bg{
		position: absolute;
		z-index: 10;
		left:0;
		top:0;
		height:100%;
		width:100%;
		overflow: hidden;
		img{
			height:100%;
			width:100%;
			object-fit: cover;
			/*-webkit-filter: blur(5px) contrast(30%) brightness(150%) opacity(50%);
    		filter: blur(5px) contrast(30%) brightness(150%) opacity(50%);*/
		}
	}
	.features-content{
		position: relative;
		z-index: 20;
		color:@gray-mid-dark;
		.features-title{
			font-size: @font-size-h3;
			margin: 0 0 0.25em 0;
			@media @wide{
				font-size: @font-size-h2;
			}
		}
		.features-sec-header{
			font-size: @font-size-large;
			margin: 0 0 0.5em 0;
		}
		.features-body{
			font-size: @font-size-xsmall;
			margin: 0 0 0.25em 0;
			line-height: 2;
			@media @wide{
				font-size: @font-size-small;
			}
		}
		.features-link{
			font-size: @font-size-xsmall;
			margin: 1em 0 0 0;
			line-height: 2;
			font-weight: 500;
			a{
				color: @gray-mid-dark;
			}
		}
		.features-main-img{
			text-align: -webkit-center;
			text-align: center;
			padding:2em 0;
		}
		.features-main-text{
			margin:0 0 2em 0;
			padding:0;
		}
		@media @tablet{
			.features-main-img{
				padding:2em 0;
			}
			.features-main-text{
				margin:2em 0;
			}
		}
		@media @normal{
			.features-main-img{
				padding:3em 0;
			}
			.features-main-text{
				margin:3em 0;
			}
		}
		@media @wide{
			font-size: @font-size-small;
			.features-main-img{
				padding:3em 0;
			}
			.features-main-text{
				margin:4em 0;
				padding-left:5em;
			}
		}
	}
	&.revert{
		.features-content{
			.features-main-img{
				float:right;
			}
			.features-main-text{
				padding:0 5em 0 0;
			}
		}
	}
	&.no-image-padding{
		.features-content{
			position: relative;
			@media @tablet{
				.features-main-img{
					height:100%;
					position: relative;
					padding:4em 0 0 0;
					img{
						display: inline-block;
					}
				}
			}
		}
	}
	&.gradient-left{
		&:before{
			content:'';
			width:100%;
			height:100%;
			position: absolute;
			top:0;
			left:0;
			z-index:11;
			background: -moz-linear-gradient(left, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 50%);
			background: -webkit-linear-gradient(left, rgba(255,255,255,1) 5%,rgba(255,255,255,0) 50%);
			background: linear-gradient(to right, rgba(255,255,255,1) 5%,rgba(255,255,255,0) 50%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
		}
	}
	&.gradient-right{
		&:before{
			content:'';
			width:100%;
			height:100%;
			position: absolute;
			top:0;
			right:0;
			z-index:11;
			background: -moz-linear-gradient(left, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 95%);
			background: -webkit-linear-gradient(left, rgba(255,255,255,0) 50%,rgba(255,255,255,1) 95%);
			background: linear-gradient(to right, rgba(255,255,255,0) 50%,rgba(255,255,255,1) 95%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
		}
	}
}
.sec_slider{
	padding:3em 0;
	background: @blue-grey-50;
	.slick{
		position: relative;
	}
	.views-field-nothing{
		.field-content{
			display: block;
			.clearfix;
			.sec-slider-image{
				@media @tablet{
					margin-bottom:0;
				}
				img{
					width:100%;
					height: auto;
					object-fit: cover;
				}
			}
			.sec-slider-text{
				font-size: @font-size-xsmall;
				.sec-slider-title{
					font-size:@font-size-h4;
					margin:1em 0 0.5em 0;	
					a{
						color:@text-color;
					}
				}
				.sec-slider-link{
					text-align: left;
					margin-top:1em;
					a{
						.btn;
						background: @white;
						color:@gray-mid-dark;
						border-color:@gray-more-light;
						font-size: @font-size-small;
						font-weight: 400;
					}
				}
				@media @normal{
					font-size: @font-size-base;
				}
				@media @wide{
					.sec-slider-title{
						font-size:@font-size-h3;
					}
				}
			}
		}
	}
	.slick__arrow{
		text-align: center;
		margin-top:2em;
		@media @wide{
			position: absolute;
			right:0;
			margin-top:0;
		}
		button{
			display: inline-block;
			font-size: 0;
			background: none;
			border:none;
			padding:0;
			margin: 0;
			width:50px;
			&:before{
				display:inline-block;
				width:40px;
				height:40px;
				.border-radius(20px);
				border:1px solid @gray;
				text-align: center;
				font-family: @font-family-awesome;
				font-size: @font-size-h3;
				font-weight: normal;
				color:@gray;
				padding:0;
				line-height: 36px;
			}
			&:nth-child(1){
				left:-20px;
				&:before{
					content:'\f104';
				}
				@media @tablet{
					left:-30px;
				}
			}
			&:nth-child(2){
				right: -20px;
				&:before{
					content:'\f105';
				}
				@media @tablet{
					right:-30px;
				}
			}
		}
	}
}
.blog{
	padding:1em @grid-gutter-width/4 0 @grid-gutter-width/4;
}
.view-blog{
	.view-content{
		.flex-container(row, wrap, flex-start, stretch, flex-start);
	}
}

.blog-item{
	text-align: left;
	padding: 0 @grid-gutter-width/8;
	margin-bottom:3em;
	.flex-element(0, 0, 0, 100%);
	@media @tablet{
		.flex-element(0, 0, 0, 33.333333%);
	}
	.views-field-title{
		margin:1em 0 0.5em 0;
		font-size: @font-size-large;
		a{
			color:@text-color;
		}
		@media @wide{
			font-size: @font-size-h3;
		}
	}
	.views-field-body{
		color:@gray;
	}
	.views-field-field-image{
		.field-content{
			display: block;
			height:160px;
			img{
				height:100%;
				width:100%;
				object-fit: cover;
			}
			@media @normal{
				height:240px;
			}
			@media @wide{
				height:300px;
			}
			@media @xwide{
				height:360px;
			}
		}
	}
}
.cta{
	padding:3em 0;
	background: @blue-grey-50;
	text-align: center;
	.block-title{
		font-size: @font-size-large;
		text-align: center;
		font-weight: 400;
		color:@gray-mid-dark;
		text-transform: none;
		margin-bottom:1em;
		@media @normal{
			font-size: @font-size-h4;
		}
	}
	.cta-btn{
		display: inline-block;
		text-align: center;
		vertical-align: top;
		padding:0 0.25em 0 0.25em;
		.btn{
			font-weight: 400;
			margin-bottom:0.5em;
		}
	}
}

.footer{
	padding:3em 0 2em 0;
	margin:0;
	background: @white;
	color:@gray-mid-dark;
	.container{
		background: none;
	}
	.footer-block {
		padding-top:0;
		font-size: @font-size-small;
		margin-bottom:1em;
		@media @tablet{
			padding-top:0;
		}
		h2, h3{
			font-size:@font-size-base;
			margin:0 0 0.5em 0;
			text-align: left;
			font-weight:400;
			color:@text-color;
		}
		a{
			color:@gray-mid-dark;
			&:hover{
				color:@theme-color-1;
				text-decoration: underline;
			}
		}
		.nav{
			margin:0;
			padding:0;
			li{
				margin: 0;
				padding: 0;
				display: block;
				a{
					margin: 0;
					padding: 0 0 0.5em 0;
					background:none;
					color:@gray-mid-dark;
					&:hover{
						color:@theme-color-1;
						text-decoration: underline;
					}
				}
			}
		}
		&.copy{
			@media @wide{
				text-align: right;
			}
		}
	}
}
.node-type-article{
	.field-type-image{
		margin:0 0 10px 30px;
		float:right;
		border:10px solid #eee;
	}
}
.node-features{
	.field-type-image{
		float:right;
	}
}
#slick-views-testimonials-3-slider{
	p{
		font-style:italic;
	}
}

.view-pricing{
	margin:3em 0;
	font-size:@font-size-xsmall;
	@media @wide{
		font-size: @font-size-small;
	}
	&:after,
	&:before{
		content:'';
		display:table;
		clear:both;
	}
	.price-item{
		float:left;
		width:25%;
		border-left:1px solid @gray-more-light;
		&:nth-child(1){
			border-left:none;
			.title{
				background:@theme-color-1;
				font-size:@font-size-large;
				font-weight:500;
				color:@white;
			}
		}
		.title{
			background:@gray-more-light;
			font-size:@font-size-large;
			font-weight:500;
			color:@text-color;
		}
		/*&:nth-last-child(1){
			.title{
				background:@theme-color-1;
				font-size:@font-size-large;
				font-weight:500;
				color:@white;
			}
		}*/
		> div{
			padding:0.75rem;
			text-align:center;
			height:60px;
			vertical-align:middle;
			&:nth-child(2n){
				background:@gray-lighter;
			}
		}
		.price{
			font-size:@font-size-h3;
			font-weight:500;
		}
		&.price-header{
			div{
				font-weight:300!important;
				font-size:@font-size-xsmall;
				@media @wide{
					font-size: @font-size-small;
				}
			}
			.title{
				background:none !important;
			}
		}
	}
}
#cboxContent{
	.node-video{
		h2{
			display: none;
		}
		iframe{
			width:100%;
			height:570px;
		}
	}
}
.breadcrumbs{
	.container{
		padding:0;
		margin-top:15px;
	}

}
.breadcrumb {
   
    font-size: 10pt;
}

/**************************************************

	AUTHOR: ThemeGap
	ITEM: Price Plan
	VERSION: 1.2

*********************************************************/

/**************************************************

	TABLE OF CONTENTS
	
	1.  MISCELLANEOUS
	2.  PRICE HEADING
	3.  PRICE LIST
	4.  PRICE BUTTON
	5.  PRICE AMOUNT
	6.  PRICE ICONS

*********************************************************/

@charset "utf-8";

/*******************************************************************/
/* 1. Miscellaneous */

.price-section {
	width: 100%;
}

/*******************************************************************/
/* 2. Price heading */

.price-heading {
	font-size: 23px;
	padding: 25px;
	line-height: 30px;
	color: #fff;
	background: #00B5D5;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}

/*******************************************************************/
/* 3. Price list */

.pricing {
	background: #f6f6f6;
    padding: 11px;
	line-height: 30px;
}

.price-list {	
	font-size: 14px;	
    margin: 32px 0px 32px 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
	transition: all .2s ease-in-out;
	box-shadow: 0px 1px 2px #e0e0e0;
}

.price-list-circle {
	font-size: 14px;	
    margin: 52px 0px 52px 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
	transition: all .2s ease-in-out;
	box-shadow: 0px 1px 2px #e0e0e0;
}

.price-item {
	padding: 11px;
	line-height: 25px;
    border-bottom: 1px solid #e0e0e0;
}

.price-list:hover {
	transform: scale(1.05);
	color: #fff;
    background: #00B5D5;
}

.price-list-circle:hover {
	transform: scale(1.05);
	color: #fff;
    background: #00B5D5;
}

/*******************************************************************/
/* 4. Price button */

.price-button {
	border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
	background: #00B5D5;
	font-size: 18px;
}

.price-button a {
	color: #fff;
    width: 100%;
    padding: 18px;
    display: block;
    height: 100%;
}

.price-button a:hover {
	text-decoration: none;
}

/*******************************************************************/
/* 5. Price amount */

.price-amount {
	font-size: 25px;
	color: #00B5D5;
}

.price-txt {
	color: #939393;
}

.price-circle {
    display: block;
    width: 120px;
    border-radius: 50%;
    font-size: 23px;
	line-height: 20px;
    padding: 33px 5px 0px 0px;
    color: #00B5D5;
    border: 10px solid #00B5D5;
    height: 120px;
    margin: -70px auto 10px auto;
    background: #fff;
}

.monthly {
    font-size: 10px;
    display: block;
    color: #00B5D5;
    margin-left: 10px;
}

/*******************************************************************/
/* 6. Price icons */

.price-list i {
	display: block;
    background: #fff;
    border-radius: 50%;
    padding: 14px 0px;
    width: 50px;
    color: #00B5D5;
    margin: 10px auto;
    height: 50px;
}

.icon-circle {
	display: block;
    width: 120px;
    border-radius: 50%;
    color: #00B5D5;
    border: 10px solid #00B5D5;
    height: 120px;
    margin: -70px auto 10px auto;
    background: #fff;
}

.icon-circle i {
	padding: 30px 0px;
    font-size: 40px;
}



#colorbox{
	.alert{
		padding:1rem;
		font-size:@font-size-xsmall;
		margin-bottom:1rem;
	}
	.node-webform{
		.field-name-title{
			h2{
				margin: 0 0 1rem 0;
				font-size:@font-size-h3;
				text-transform:uppercase;
			}
		}
		.webform-client-form{
			background: @gray-lightest;
			padding:2rem;
			.form-group{
				margin:0;
			}
			.form-item{
				margin: 0 0 0.5rem 0;
				font-size:@font-size-xsmall;
				.control-label{
					margin-bottom:0;
				}
				input, select{
					height:32px;
					padding:0.25rem 0.5rem;
					font-size:@font-size-xsmall;
				}
				textarea{
					padding:0.5rem;
					height:90px;
					font-size:@font-size-xsmall;
				}
			}
		}
	}
}
#sliding-popup .popup-content #popup-text h1, #sliding-popup .popup-content #popup-text h2, #sliding-popup .popup-content #popup-text h3, #sliding-popup .popup-content #popup-text p{
	font-weight: 400;
}
#sliding-popup .eu-cookie-compliance-more-button{
	font-weight: 400;
}
.roi-calc{
	margin:2rem 0;
	padding:2rem 0;
	border-top:1px solid @gray-lighter;
	.block-title{
		margin: 0 0 1rem 0;
		font-size:@font-size-h3;
		text-transform:uppercase;
	}
	form{
		.clearfix;
		text-align:center;
		#client_intro{
			margin-bottom:2rem;
			display: block;
			text-align:center;
			font-size:@font-size-small;
			.client_intro_cta{
				font-size:@font-size-large;
				font-weight:300;
			}
			@media @xwide{
				width:66%;
				margin:0 auto 2rem auto;
			}
			>div{
				margin: 0 0 0.5rem 0;
			}
		}
		.form-item{
			display: inline-block;
			margin: 0 1rem 1rem 1rem;
			width:90%;
			label, input{
				display: inline-block;
				margin: 0 0.5rem;
				vertical-align: middle;
				width: auto;
			}
			input{
				width:120px;
			}
			label{
				width:190px;
			}
			@media @tablet{
				width:45%;
				input{
					width:140px;
				}
				label{
					width:auto;
				}
				&.form-item-client-number-of-instance{
					text-align:right;
				}
				&.form-item-client-hourly-cost{
					text-align:left;
				}
			}
			@media @normal{
				input{
					width:210px;
				}
			}
			@media @wide{
				width:auto;
			}
		}
	}
	#client_result{
		padding-top:2rem;
		border-top:1px solid @gray-lighter;
		h1{
			margin:1rem 0;
			font-size:@font-size-h3;
			text-transform:uppercase;
			color:@theme-color-1;
		}
		.result-value{
			font-weight:700;
			color:@theme-color-1;
		}
	}
}
.price-section{
	@media @wide{
		.row > .col-lg-3:first-child{
			margin-left:12.5%
		}
	}
}

.register-button{
	text-align: center;
	margin-top: 10px;
	@media @tablet{
    	margin-top: -28px;
    }
    z-index: 999;
    position: relative;
    .btn{
    	font-size: @font-size-large;
    	padding: .5em 2em;
    }
}

.HPVideo{
	iframe{
		max-width:650px;
	}
}


.bottom-btn{
    	.btn{
	    	font-size: @font-size-large;
	    	padding: .5em 2em;
	    }
}

.front .covid-19 {background: rgb(31, 42, 68); padding:15px; }
.front .covid-19 h2 {font-weight:500 !important; color:#fff !important; text-align: center; margin-top: 10px; margin-bottom: 10px;}
.front .covid-19 h4 {font-weight:500 !important; color:#fff !important; font-size:16px; line-height: 22px;}

.not-front .covid-19 {background: rgb(31, 42, 68); padding:0; }
.not-front .covid-19 h2 {font-weight:600 !important; color:#fff !important; text-align: center; margin-top: 10px; margin-bottom: 10px;}
.not-front .covid-19 h4 {font-weight:500 !important; color:#fff !important; font-size:16px; line-height: 22px;}

.not-front #highlighted {background: rgb(31, 42, 68); padding:30px 0 10px 0;}

@media screen and (max-width: 500px){

 .front .covid-19 {background: rgb(31, 42, 68); padding:15px; text-align: center; }
 .front .covid-19 h2 {font-weight:600 !important; color:#fff !important; text-align: center; margin-top: 10px; margin-bottom: 10px;  font-size: 20px;}
 .front .covid-19 h4 {font-weight:500 !important; color:#fff !important; font-size:14px; line-height: 22px;}

 .not-front .covid-19 {background: rgb(31, 42, 68); padding:15px; text-align: center; }
 .not-front .covid-19 h2 {font-weight:600 !important; color:#fff !important; text-align: center; margin-top: 10px; margin-bottom: 10px;  font-size: 20px;}
 .not-front .covid-19 h4 {font-weight:500 !important; color:#fff !important; font-size:14px; line-height: 22px;}

.not-front #highlighted {background: rgb(31, 42, 68); padding:0 0 0 0;}
}