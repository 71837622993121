/**
 * Variable Overrides.
 *
 * Modify this file to override the Bootstrap Framework variables. You can copy
 * these variables directly from ../bootstrap/less/variables.less to this file.
 */

// Set the proper directory for the Bootstrap Glyphicon font.
@icon-font-path: '../bootstrap/fonts/';


@gray-base:              #333;
@gray-dark:              @grey-900;
@gray-mid-dark:          @grey-800;
@gray:                   @grey-500;
@gray-light:             @grey-400;
@gray-more-light:        @grey-300;
@gray-lighter:           @grey-100;
@gray-lightest:          @grey-50;
@gray-trans: rgba(0, 0, 0, 0.25);
@white: #fff;
 
@body-bg: @white;

/*See all colors in _colors.less*/
@theme-color-1: #D13239;
@theme-color-2: @red-700;
@theme-color-3: @blue-grey-700;

@text-color:            @gray-base;
@link-color:            @theme-color-1;
@link-hover-color:      darken(@link-color, 15%);

@font-family-sans-serif: 'Roboto', sans-serif;
@font-family-awesome:  'FontAwesome';

@font-family-base:        @font-family-sans-serif;

@font-size-base:          12pt;
@font-size-large:         16pt;
@font-size-small:         11pt;
@font-size-xsmall:        10.5pt;
@font-size-h1:            24pt;
@font-size-h2:            20pt;
@font-size-h3:            16pt;
@font-size-h4:            14pt; 
@font-size-h5:            14pt;
@line-height-base:        1.6;


@pagination-color:                     @theme-color-1;
@pagination-bg:                        @white;
@pagination-border:                    @gray-lighter;

@pagination-hover-color:               @theme-color-1;
@pagination-hover-bg:                  @gray-lighter;
@pagination-hover-border:              @gray-lighter;

@pagination-active-color:              @white;
@pagination-active-bg:                 @theme-color-1;
@pagination-active-border:             @theme-color-1;

@pagination-disabled-color:            @gray-light;
@pagination-disabled-bg:               @white;
@pagination-disabled-border:           @gray-lighter;

@grid-gutter-width:         40px;
@screen-xlg:                  1500px;
@screen-xlg-min:              @screen-xlg;
@screen-xlg-desktop:          @screen-xlg-min;
@screen-xlg-max:               (@screen-xlg-min - 1);
@container-xlarge-desktop:      (1440px + @grid-gutter-width);
@container-xlg:                 @container-xlarge-desktop;